







import { defineComponent, ref, computed } from '@vue/composition-api';
import { useUserStore } from '@/js/lib/store/user';
import { useMemberStore } from '@/js/lib/store/member';
import { followMember, unFollowMember } from '@/js/lib/api/member';
import { loading } from '@/js/lib/ui';
import { signinSignup } from '@/js/lib/ui/signin-signup';

export default defineComponent({
  props: {
    memberId: {
      type: Number,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const userStore = useUserStore();
    const memberStore = useMemberStore();
    const isLoading = ref(false);
    const isFollowed = computed(() => memberStore.followedMemberIds.includes(props.memberId));
    const follow = async () => {
      memberStore.addFollowedMemberId(props.memberId);
      await followMember({ memberId: props.memberId }).catch(() => {
        memberStore.removeFollowedMemberId(props.memberId);
      });

      window.dataLayer?.push({
        event: 'follow',
        event_category: 'kpi',
        event_label: 'shop',
      });
    };
    const unfollow = async () => {
      memberStore.removeFollowedMemberId(props.memberId);
      await unFollowMember({ memberId: props.memberId }).catch(() => {
        memberStore.addFollowedMemberId(props.memberId);
      });
    };
    const onClick = async () => {
      if (userStore.isAuthenticated) {
        if (isLoading.value) {
          return;
        }

        isLoading.value = true;
        await (isFollowed.value ? unfollow() : follow());
        isLoading.value = false;
      } else {
        signinSignup({
          next: window.location.href,
          callback: async () => {
            await loading({
              asyncFunc: follow,
              text: 'ログインしています',
            });
            window.location.reload();
          },
        });
      }
    };

    return {
      isFollowed,
      onClick,
    };
  },
});
