




















import { defineComponent, computed, getCurrentInstance } from '@vue/composition-api';
import { type ComponentInternalInstanceWithMQ } from '@/types';
import { useUserStore } from '@/js/lib/store/user';
import { useCartStore } from '@/js/lib/store/cart';
import { credentials } from '@/js/lib/api/user';
import { NativeView, urlForDeepLink } from '@/js/lib/helper/yappli';
import IModal from '@/js/components/molecules/IModal.vue';

export default defineComponent({
  components: {
    IModal,
  },
  props: {
    fromNative: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const currentInstance = getCurrentInstance();
    const userStore = useUserStore();
    const cartStore = useCartStore();
    const isPreSeller = userStore.hasCredential(credentials.preSeller);
    const isSeller = userStore.hasCredential(credentials.seller);
    const itemInCart = computed(() => cartStore.itemCount > 0);
    const backToApp = computed(() => props.fromNative && (currentInstance as ComponentInternalInstanceWithMQ).proxy.$mq === 's');

    const textOk = computed(() => {
      let text = '';
      if (backToApp.value) {
        text = 'アプリに戻る';
      } else if (isSeller) {
        text = 'マイページ';
      } else if (isPreSeller) {
        text = 'ショップを開設する';
      } else if (itemInCart.value) {
        text = 'カートに進む';
      }
      return text;
    });
    const onOK = () => {
      if (backToApp.value) {
        window.location.href = itemInCart.value ? urlForDeepLink(NativeView.CART) : urlForDeepLink(NativeView.WEBVIEW, { url: window.location.href });
      } else if (isPreSeller) {
        window.location.href = '/signup/shop';
      } else if (isSeller) {
        window.location.href = '/your/dashboard';
      } else if (itemInCart.value) {
        window.location.href = '/cart';
      }
    };
    const modalClose = () => {
      emit('close');
    };

    return {
      isPreSeller,
      isSeller,
      textOk,
      onOK,
      modalClose,
    };
  },
});
