









import { defineComponent } from '@vue/composition-api';
import IModal from '@/js/components/molecules/IModal.vue';

export default defineComponent({
  components: {
    IModal,
  },
  props: {
    loadingText: {
      type: String,
      default: '',
    },
  },
});
