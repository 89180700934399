



































import { register } from 'swiper/element/bundle';
import { defineComponent, ref, computed, onMounted, type PropType } from '@vue/composition-api';
import { dynamicImageUrl } from '@/js/lib/helper/url';
import IModal from '@/js/components/molecules/IModal.vue';
import PhotoDialog from './PhotoDialog.vue';
import type Swiper from 'swiper';

const getImageUrlToFillSquare = (path: string, width: number, height: number, squareSize: number) => {
  let w: number | undefined;
  let h: number | undefined;

  if (width > height) {
    h = squareSize;
  } else {
    w = squareSize;
  }

  return dynamicImageUrl(path, w, h);
};

// register Swiper custom elements
register();

export default defineComponent({
  components: {
    IModal,
    PhotoDialog,
  },
  props: {
    pictures: {
      type: Array as PropType<
        {
          path: string;
          width: number;
          height: number;
          comment: string;
        }[]
      >,
      required: true,
    },
    hasThumbnail: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const list = ref(props.pictures);
    const viewport = document.querySelector('meta[name=viewport]') as HTMLMetaElement;
    const injectStyles = [
      `
      :host {
        height: 100%;
      }
      :host .swiper-button-prev,
      :host .swiper-button-next {
        color: #fff;
        background: none rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        height: 38px;
        width: 38px;
      }
      :host .swiper-button-prev:hover,
      :host .swiper-button-next:hover {
        opacity: 0.7;
      }
      :host .swiper-button-disabled {
        opacity: 0.25;
        pointer-events: initial;
      }
      :host .swiper-button-prev > svg {
        height: 50%;
        margin-right: 3px;
      }
      :host .swiper-button-next > svg {
        height: 50%;
        margin-left: 3px;
      }
      `,
    ];
    const wrapperRef = ref();
    const thumbRef = ref();
    const isShowModal = ref(false);
    const swiper = ref<Swiper>();
    const currentIndex = computed(() => swiper.value?.realIndex || 0);
    const onThumbClick = (index: number) => {
      swiper.value?.slideTo(index);
    };
    const onOpen = () => {
      // モーダル表示時に画像を拡大できるようにする
      if (viewport?.content) {
        const viewportArr = viewport.content.split(', ');
        viewportArr.splice(viewportArr.indexOf('maximum-scale=1.0'), 1);
        viewport.content = viewportArr.join(', ');
      }
    };
    const onClose = () => {
      if (viewport?.content) {
        viewport.content = [...viewport.content.split(', '), 'maximum-scale=1.0'].join(', ');
      }
    };

    onMounted(() => {
      if (list.value.length > 1) {
        Object.assign(wrapperRef.value, {
          cssMode: true,
          navigation: true,
          injectStyles,
        });

        wrapperRef.value.addEventListener('swiperprogress', (e) => {
          [swiper.value] = e.detail;
        });
        wrapperRef.value.addEventListener('swiperslidechange', () => {
          thumbRef.value?.children[currentIndex.value]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        });
        wrapperRef.value.initialize();
      }
    });

    return {
      injectStyles,
      wrapperRef,
      thumbRef,
      isShowModal,
      list,
      currentIndex,
      getImageUrlToFillSquare,
      onThumbClick,
      onOpen,
      onClose,
    };
  },
});
