






import { defineComponent, computed } from '@vue/composition-api';
import { addToBasket } from '@/js/lib/api/cart';
import { useCommonStore } from '@/js/lib/store/common';
import { useUserStore } from '@/js/lib/store/user';
import { useCartStore } from '@/js/lib/store/cart';
import { info } from '@/js/lib/ui';

import { mountModal } from '@/js/lib/ui/helper/dynamic-modal';
import IButton from '@/js/components/molecules/IButton.vue';
import CartModal from '@/js/components/www.iichi.com/organisms/modals/CartModal.vue';

export default defineComponent({
  components: {
    IButton,
  },
  props: {
    itemId: {
      type: Number,
      default: undefined,
    },
    shopId: {
      type: Number,
      default: undefined,
    },
    stock: {
      type: Number,
      default: undefined,
    },
    buttonLabel: {
      type: String,
      default: undefined,
    },
    isOnVacation: {
      type: Number,
      default: undefined,
    },
  },
  setup(props) {
    const { isWebView } = useCommonStore();
    const userStore = useUserStore();
    const cartStore = useCartStore();
    const available = computed(() => {
      if (props.shopId === userStore.memberId || props.isOnVacation || userStore.isBlackListed) {
        return false;
      }

      return props.stock > 0;
    });
    const onClickAddToCart = async () => {
      if (available.value) {
        // 以前のeコマースオブジェクトをクリアする
        // eslint-disable-next-line unicorn/no-null
        window.dataLayer?.push({ ecommerce: null });
        window.dataLayer?.push({
          event: 'add_to_cart',
          ecommerce: {
            items: [
              {
                item_id: props.itemId,
                quantity: 1,
              },
            ],
          },
        });
        try {
          await addToBasket({ itemId: props.itemId });
          if (isWebView) {
            info('カートに追加されました', { fadeOut: true });
            return;
          }
          await cartStore.loadBaskets();
          mountModal({
            Component: CartModal,
          });
        } catch {
          info('カートに追加できませんでした。');
        }
      }
    };

    return {
      available,
      onClickAddToCart,
    };
  },
});
