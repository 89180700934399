




import { defineComponent } from '@vue/composition-api';
import { useUserStore } from '@/js/lib/store/user';
import { useItemStore } from '@/js/lib/store/item';
import FollowRecommend from './followRecommend.vue';

export default defineComponent({
  components: {
    FollowRecommend,
  },
  props: {
    itemId: {
      type: Number,
      default: undefined,
    },
    memberId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const userStore = useUserStore();
    const itemStore = useItemStore();

    return {
      userStore,
      itemStore,
    };
  },
});
