







































import { defineComponent, ref } from '@vue/composition-api';
import { messageStart } from '@/js/lib/api/message';
import { useUserStore } from '@/js/lib/store/user';
import { credentials } from '@/js/lib/api/user';
import { info, loading } from '@/js/lib/ui';
import { confirm } from '@/js/lib/ui/confirm';
import { signinSignup } from '@/js/lib/ui/signin-signup';
import IButton from '@/js/components/molecules/IButton.vue';
import IModal from '@/js/components/molecules/IModal.vue';
import IMenu from '@/js/components/molecules/IMenu.vue';
import ITextArea from '@/js/components/molecules/ITextArea.vue';
import ITextField from '@/js/components/molecules/ITextField.vue';
import PhotoUploader, { type Picture } from '@/js/components/common/ui/form/photo-uploader.vue';

export default defineComponent({
  components: {
    IButton,
    IModal,
    IMenu,
    ITextArea,
    ITextField,
    PhotoUploader,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    itemId: {
      type: String,
      required: false,
      default: '',
    },
    shopId: {
      type: String,
      required: true,
    },
    conversationType: {
      type: String,
      required: true,
    },
    subject: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const userStore = useUserStore();
    const observer = ref();
    const isShowMessageDialog = ref(false);
    const isShowImageNote = ref(false);
    const subjectStr = ref(props.subject);
    const messageStr = ref(props.itemId ? `\n\n${window.location.href.replace(window.location.search, '').replace(window.location.hash, '')}` : '');
    const pictures = ref<Picture[]>([]);
    const onClick = () => {
      if (userStore.isAuthenticated) {
        if (userStore.hasCredential(credentials.emailConfirmed)) {
          isShowMessageDialog.value = true;
        } else {
          info('メッセージを送信するにはメール認証が必要です。\n「マイページ > 設定 > ログイン情報 > メールアドレスの変更」よりメール認証を行ってください。');
        }
      } else {
        signinSignup({
          next: window.location.href,
          callback: () => {
            window.location.reload();
          },
        });
      }
    };
    const onSubmit = async () => {
      const isValid = await observer.value.validate();
      if (!isValid) {
        return;
      }
      if (await confirm('メッセージを送信します。よろしいですか？')) {
        await loading({
          asyncFunc: async () => {
            await messageStart({
              itemId: props.itemId,
              conversationType: props.conversationType,
              shopId: props.shopId,
              pictures: pictures.value,
              subjectStr: subjectStr.value,
              messageStr: messageStr.value,
            });
            window.location.reload();
          },
        });
      }
    };

    return {
      observer,
      isShowMessageDialog,
      isShowImageNote,
      subjectStr,
      messageStr,
      pictures,
      onClick,
      onSubmit,
    };
  },
});
