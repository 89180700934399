import { readonly, ref, computed } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { getTodos, type TodoItem } from '@/js/lib/api/todo';

export const useTodoStore = defineStore('todo', () => {
  const isLoaded = ref(false);
  const todos = ref<TodoItem[]>([]);
  const totalCount = ref(0);

  async function loadTodos() {
    isLoaded.value = true;
    try {
      const res = await getTodos();
      todos.value = res.todos;
      totalCount.value = res.totalCount;
    } catch {
      todos.value = [];
      totalCount.value = 0;
    } finally {
      isLoaded.value = true;
    }
  }

  return {
    isLoaded: readonly(isLoaded),
    todos: readonly(todos),
    totalCount: computed(() => Number(totalCount.value)),
    loadTodos,
  };
});
