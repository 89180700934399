import { readonly, ref } from '@vue/composition-api';
import { defineStore } from 'pinia';
import * as api from '@/js/lib/api/message';

export const useMessageStore = defineStore('message', () => {
  const unreadCount = ref(0);

  async function checkUnreadCount() {
    try {
      const res = await api.checkUnreadCount();
      unreadCount.value = res.unreadCount;
    } catch {
      unreadCount.value = 0;
    }
  }

  return { unreadCount: readonly(unreadCount), checkUnreadCount };
});
