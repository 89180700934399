import Vue from 'vue';
import { ValidationProvider, ValidationObserver, setInteractionMode, localize, extend } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';
import * as rules from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

setInteractionMode('eager');

ja.messages = {
  ...ja.messages,
  // 型エラー回避のためにスプレッド構文で結合する
  // eslint-disable-next-line unicorn/no-useless-spread
  ...{
    tel: '電話番号が正しくありません',
    email: 'メールアドレスが正しくありません',
    zip: '郵便番号が正しくありません',
    accountNumber: '半角数字7桁で入力して下さい',
    accountName: '全角カナ・アルファベット・数字で入力して下さい',
  },
};

localize('ja', ja);
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
extend('alpha_num', {
  validate(value) {
    return /^[\dA-Za-z]+$/.test(value);
  },
});
extend('tel', {
  validate(value) {
    return /^0[\d-]{9,}$/.test(value);
  },
});
extend('email', {
  validate(value) {
    return /^[\w+.-]+@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/.test(value);
  },
});
extend('zip', {
  validate(value) {
    return /^\d{3}-?\d{4}$/.test(value);
  },
});
extend('accountNumber', {
  validate(value) {
    return /^\d{7}$/.test(value);
  },
});
extend('accountName', {
  validate(value) {
    // eslint-disable-next-line no-irregular-whitespace
    return /^[0-9０-９a-zａ-ｚA-ZＡ-Ｚ（）・ァ-ヶ―ー－ 　，．「」／￥]*$/u.test(value);
  },
});
