






import { defineComponent } from '@vue/composition-api';
import IModal from '@/js/components/molecules/IModal.vue';

const goToCart = () => {
  window.location.href = '/cart';
};

export default defineComponent({
  components: {
    IModal,
  },
  setup(_props, { emit }) {
    const modalClose = () => {
      emit('close');
    };

    return {
      goToCart,
      modalClose,
    };
  },
});
