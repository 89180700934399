// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhotoViewer__photoPager--EPuD5{background-color:rgba(0,0,0,.4);border-radius:20px;color:#fff;font-size:12px;padding:2px 10px;position:absolute;right:10px;top:10px;z-index:1}", "",{"version":3,"sources":["webpack://./assets/src/js/components/www.iichi.com/organisms/PhotoViewer.vue","webpack://./assets/src/scss/base/_variables.scss"],"names":[],"mappings":"AA8KA,gCACE,+BAAA,CACA,kBAAA,CACA,UC/IM,CDgJN,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,UAAA,CACA,QAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"photoPager": "PhotoViewer__photoPager--EPuD5"
};
module.exports = ___CSS_LOADER_EXPORT___;
