
















import { defineComponent, computed, getCurrentInstance } from '@vue/composition-api';
import { type ComponentInternalInstanceWithMQ } from '@/types';
import IModal from '@/js/components/molecules/IModal.vue';
import { NativeView, urlForDeepLink } from '@/js/lib/helper/yappli';

const onTapToGoBackToApp = () => {
  window.location.href = urlForDeepLink(NativeView.HOME);
};

export default defineComponent({
  components: {
    IModal,
  },
  props: {
    fromNative: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const currentInstance = getCurrentInstance();
    const backToApp = computed(() => props.fromNative && (currentInstance as ComponentInternalInstanceWithMQ).proxy.$mq === 's');
    const modalClose = () => {
      emit('close');
    };

    return {
      backToApp,
      onTapToGoBackToApp,
      modalClose,
    };
  },
});
