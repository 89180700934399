
















import { defineComponent, ref } from '@vue/composition-api';
import { confirmEmail } from '@/js/lib/api/account/account';
import { loading } from '@/js/lib/ui';
import { info } from '@/js/lib/ui/info';
import IModal from '@/js/components/molecules/IModal.vue';

export default defineComponent({
  components: {
    IModal,
  },
  setup() {
    const isShow = ref(true);
    const send = async () => {
      isShow.value = false;
      await loading({
        asyncFunc: async () => {
          try {
            await confirmEmail({ next: window.location.href });
            info('認証メールを送信いたしました\nメールの案内に従いメールアドレス認証を完了させてください。');
          } catch (error) {
            info(error.message || 'メールを送信できませんでした。\nお手数ですが、ブラウザを再読み込みして再度お試しください。');
          }
        },
      });
    };

    return {
      isShow,
      send,
    };
  },
});
