import { computed, readonly, ref } from '@vue/composition-api';
import { defineStore } from 'pinia';
import * as userApi from '@/js/lib/api/user';

export const useCommonStore = defineStore('common', () => {
  const activityCount = ref(0);
  const isNavigationMenuOpen = ref(false);

  const checkActivity = async () => {
    const { count } = await userApi.activityCount();
    activityCount.value = count;
  };

  const isWebView = computed(() => document.body.classList.contains('webview'));

  const toggleNavigationMenu = () => {
    isNavigationMenuOpen.value = !isNavigationMenuOpen.value;
    if (isNavigationMenuOpen.value) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  };

  return { activityCount: readonly(activityCount), checkActivity, isNavigationMenuOpen: readonly(isNavigationMenuOpen), isWebView, toggleNavigationMenu };
});
