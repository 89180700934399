

























import { defineComponent } from '@vue/composition-api';
import IModal from '@/js/components/molecules/IModal.vue';

export default defineComponent({
  components: {
    IModal,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  setup(_, { emit }) {
    const modalClose = () => {
      emit('close');
    };

    return {
      modalClose,
    };
  },
});
