















































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';
import { requestStart } from '@/js/lib/api/item';
import { getFromStorage, setToStorage } from '@/js/lib/helper/expirable-local-storage';
import { useUserStore } from '@/js/lib/store/user';
import { useMemberStore } from '@/js/lib/store/member';
import { loading } from '@/js/lib/ui';
import IModal from '@/js/components/molecules/IModal.vue';
import ITextField from '@/js/components/molecules/ITextField.vue';
import IButton from '@/js/components/molecules/IButton.vue';
import IMenu from '@/js/components/molecules/IMenu.vue';

export default defineComponent({
  components: {
    IModal,
    ITextField,
    IButton,
    IMenu,
  },
  props: {
    itemId: {
      type: Number,
      required: true,
    },
    shopId: {
      type: Number,
      required: true,
    },
    isMe: {
      type: Number,
      required: true,
    },
    isSubscribed: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const storageKey = 'close_requestballoon';
    const userStore = useUserStore();
    const memberStore = useMemberStore();
    const observer = ref();
    const email = ref('');
    const followMember = ref(false);
    const mailMagazine = ref(false);
    const isShowMenu = ref(false);
    const isShowRequestDialog = ref(false);
    const isShowErrorDialog = ref(false);
    const errorMsg = ref('');
    const isFollowed = computed(() => memberStore.followedMemberIds.includes(props.shopId));
    const isFirst = computed(() => !getFromStorage({ key: storageKey }));
    const isDisabled = computed(() => Boolean(props.isMe) || Boolean(props.isSubscribed));
    const onMenuHide = () => {
      setToStorage({ key: storageKey, value: true, expirationSeconds: 86400 * 365 });
    };
    const onSubmit = async () => {
      const isValid = await observer.value?.validate();
      if (!isValid) {
        return;
      }
      await loading({
        asyncFunc: async () => {
          try {
            await requestStart({
              itemId: props.itemId,
              email: email.value,
              followMember: followMember.value,
              mailMagazine: mailMagazine.value,
            });
            window.location.reload();
          } catch (error) {
            if (error.response.data.message) {
              errorMsg.value = error.response.data.message;
            }
            isShowRequestDialog.value = false;
            isShowErrorDialog.value = true;
          }
        },
      });
    };

    onMounted(() => {
      isShowMenu.value = !props.isSubscribed && isFirst.value;
    });

    return {
      userStore,
      observer,
      email,
      followMember,
      mailMagazine,
      isShowMenu,
      isShowRequestDialog,
      isShowErrorDialog,
      errorMsg,
      isFollowed,
      isFirst,
      isDisabled,
      onMenuHide,
      onSubmit,
    };
  },
});
