import { readonly, ref } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { isMemberFollowing } from '@/js/lib/api/member';

export const useMemberStore = defineStore('member', () => {
  const followedMemberIds = ref<number[]>([]);
  async function getFollowedList(memberIds: number[]) {
    const followedList = await isMemberFollowing({ memberIds });
    followedMemberIds.value = followedList.filter((member) => member.liked === 1).map((member) => Number(member.memberId));
  }
  function addFollowedMemberId(memberId: number) {
    if (!followedMemberIds.value.includes(memberId)) {
      followedMemberIds.value.push(memberId);
    }
  }
  function removeFollowedMemberId(memberId: number) {
    followedMemberIds.value.splice(followedMemberIds.value.indexOf(memberId), 1);
  }

  return { followedMemberIds: readonly(followedMemberIds), getFollowedList, addFollowedMemberId, removeFollowedMemberId };
});
