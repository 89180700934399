import Vue from 'vue';
import VueMq from 'vue-mq';
import VueCompositionAPI from '@vue/composition-api';
import '@/js/lib/plugins/vee-validate';

import common from '@/js/lib/common';
import { pinia } from '@/js/lib/store';
import { useUserStore } from '@/js/lib/store/user';
import { sleep } from '@/js/lib/helper/sleep';
import { applySmoothScroll } from '@/js/lib/helper/smooth-scroll';
import { VueMqOptions } from '@/js/lib/ui/_config';
import { signinSignup } from '@/js/lib/ui/signin-signup';
import { startupTopics } from '@/js/lib/helper/startup-topics';
import { getFromStorage } from '@/js/lib/helper/expirable-local-storage';
import { mountModal } from '@/js/lib/ui/helper/dynamic-modal';
import { syncMemberIdToYappli } from '@/js/lib/helper/yappli';
import BasicDialog from '@/js/components/organisms/BasicDialog.vue';
import CampaignBanner from '@/js/components/campaign/header-banner.vue';
import IHeader from '@/js/components/common/IHeader.vue';
import history from '@/js/components/common/IHistory.vue';
import MemberRegistrationCouponModal from '@/js/components/www.iichi.com/organisms/modals/MemberRegistrationCouponModal.vue';

// Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js').catch(() => {
    // regist error
  });
}

Vue.use(VueCompositionAPI);
Vue.use(VueMq, VueMqOptions);

common.initialize(common.env.Pallet);

document.addEventListener('DOMContentLoaded', () => {
  startupTopics(common.env.Pallet.Topics);
  if (window.Yappli && common.env.Pallet.Member.member_id) {
    syncMemberIdToYappli(common.env.Pallet.Member.member_id, common.env.Pallet.Yappli.token);
  }
});

document.addEventListener('DOMContentLoaded', applySmoothScroll);

new Vue({
  el: '#header',
  components: {
    IHeader,
  },
});

if (document.querySelector('#history')) {
  new Vue({
    el: '#history',
    components: {
      history,
    },
  });
}

// キャンペーンバナー
if (document.querySelector('#campaign-banner')) {
  new Vue({
    el: '#campaign-banner',
    components: {
      CampaignBanner,
    },
  });
}

// document上へのDrag&Dropを無視する
document.addEventListener('drop', (e) => {
  e.preventDefault();
});
document.addEventListener('dragover', (e) => {
  e.preventDefault();
});

// テキストエリアの高さを自動で広げる
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('textarea').forEach((el) => {
    el.addEventListener('keyup', (event) => {
      if ((event.target as HTMLTextAreaElement).scrollTop > 0) {
        el.rows += 1;
      }
    });
  });
});

// aタグのhrefに合わせてログイン/会員登録DLGを表示
const signinSignupSet = (active: string) => {
  document.querySelectorAll(`a.js-${active}`).forEach((el: HTMLAnchorElement) => {
    const next = el.dataset.next;

    el.addEventListener('click', (e) => {
      e.preventDefault();
      if (next) {
        signinSignup({
          active,
          next,
          callback: () => {
            window.location.href = next;
          },
        });
      } else {
        signinSignup({
          active,
          callback: () => {
            window.location.reload();
          },
        });
      }
    });
  });
};

signinSignupSet('signin');
signinSignupSet('signup');

document.addEventListener('DOMContentLoaded', async () => {
  const dialogEls = document.querySelectorAll('basic-dialog');
  if (dialogEls.length > 0) {
    dialogEls.forEach((el) => {
      new Vue({
        el,
        components: {
          BasicDialog,
        },
      });
    });
  }
  const userStore = useUserStore(pinia);
  if (!userStore.isAuthenticated && !getFromStorage({ key: 'close-modal-member-registration-coupon' })) {
    await sleep(6000);
    await mountModal({ Component: MemberRegistrationCouponModal });
  }
});
