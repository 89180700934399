



















import { defineComponent, ref, onMounted, watch, nextTick } from '@vue/composition-api';
import { Dialog as ElDialog } from 'element-ui';
import { sleep } from '@/js/lib/helper/sleep';
import IButton from './IButton.vue';

export default defineComponent({
  components: {
    ElDialog,
    IButton,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: true,
    },
    width: {
      type: String,
      required: false,
      default: '500px',
    },
    textCancel: {
      type: String,
      default: 'キャンセル',
      required: false,
    },
    textOk: {
      type: String,
      default: 'OK',
      required: false,
    },
    withActionButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    withCancelButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    closeOnCancel: {
      type: Boolean,
      default: true,
      required: false,
    },
    closeOnOk: {
      type: Boolean,
      default: true,
      required: false,
    },
    fadeOut: {
      type: Boolean,
      default: false,
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const buttonOk = ref();
    const isShow = ref(false);
    const modalClose = () => {
      isShow.value = false;
      emit('close');
      emit('input', false);
    };
    const onClose = () => {
      modalClose();
    };
    const onCancel = () => {
      if (props.closeOnCancel) {
        modalClose();
      }
      emit('cancel');
    };
    const onOk = () => {
      emit('ok');
      if (props.closeOnOk) {
        modalClose();
      }
    };

    onMounted(async () => {
      isShow.value = Boolean(props.value);
      if (props.withActionButton) {
        nextTick(() => {
          if (buttonOk.value) {
            buttonOk.value.$children[0].$el.focus();
          }
        });
      }
      if (props.fadeOut) {
        await sleep(1200);
        modalClose();
      }
    });

    watch(
      () => props.value,
      () => {
        isShow.value = Boolean(props.value);
        emit(isShow.value ? 'open' : 'close');
      },
    );

    return {
      buttonOk,
      isShow,
      modalClose,
      onClose,
      onCancel,
      onOk,
    };
  },
});
