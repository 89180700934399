// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".likeButton__button--bUhnk{background-color:transparent;border-style:none;color:#121212;cursor:pointer;font-family:inherit;font-size:18px;line-height:1.6;text-decoration:none}.likeButton__button--bUhnk:hover{opacity:.8}.likeButton__button--bUhnk:before{color:#4b4b4b;content:\"\";display:inline-block;font-family:iichi-icon;font-size:48px;line-height:1;position:relative;right:-2px;top:-2px;vertical-align:middle}.likeButton__button--bUhnk.likeButton__isLiked--LTRRT:before{color:#ec4848;content:\"\"}@media only screen and (max-width:639px){.likeButton__button--bUhnk{font-size:16px}.likeButton__button--bUhnk:before{font-size:44px;right:-4px}}", "",{"version":3,"sources":["webpack://./assets/src/js/components/www.iichi.com/pages/listing/item/likeButton.vue","webpack://./assets/src/scss/base/_variables-icons.scss","webpack://./assets/src/scss/base/_variables.scss"],"names":[],"mappings":"AAAA,2BAmFA,4BACE,CAAA,iBACA,CAAA,aACA,CAAA,cACA,CAAA,mBACA,CAAA,cACA,CAAA,eACA,CAAA,oBACA,CAAA,iCAEA,UACE,CAAA,kCAGF,aACE,CAAA,WC9DuB,CAAA,oBDgEvB,CAAA,sBCnGQ,CAAA,cDqGR,CAAA,aACA,CAAA,iBACA,CAAA,UACA,CAAA,QACA,CAAA,qBACA,CAAA,6DAGF,aEhC0B,CAAA,WD3CH,CAAA,yCDgFvB,2BAhCF,cAiCI,CAAA,kCAEA,cACE,CAAA,UACA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "likeButton__button--bUhnk",
	"isLiked": "likeButton__isLiked--LTRRT"
};
module.exports = ___CSS_LOADER_EXPORT___;
