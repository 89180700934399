







import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';
import { useUserStore } from '@/js/lib/store/user';
import { useItemStore } from '@/js/lib/store/item';
import { likeItem, unlikeItem } from '@/js/lib/api/item';
import { loading } from '@/js/lib/ui';
import { signinSignup } from '@/js/lib/ui/signin-signup';

export default defineComponent({
  props: {
    itemId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const userStore = useUserStore();
    const itemStore = useItemStore();
    const isLoading = ref(false);
    const isLiked = computed(() => itemStore.likedItemIds.includes(props.itemId));
    const like = async () => {
      itemStore.addLikedItemId(props.itemId);
      await likeItem({ itemId: props.itemId }).catch(() => {
        itemStore.removeLikedItemId(props.itemId);
      });

      window.dataLayer?.push({
        event: 'like',
        event_category: 'kpi',
        event_label: 'item',
      });
    };
    const unlike = async () => {
      itemStore.removeLikedItemId(props.itemId);
      await unlikeItem({ itemId: props.itemId }).catch(() => {
        itemStore.addLikedItemId(props.itemId);
      });
    };
    const onClick = async () => {
      if (userStore.isAuthenticated) {
        if (isLoading.value) {
          return;
        }

        isLoading.value = true;
        await (isLiked.value ? unlike() : like());
        isLoading.value = false;
      } else {
        signinSignup({
          next: window.location.href,
          callback: async () => {
            await loading({
              asyncFunc: like,
              text: 'ログインしています',
            });
            window.location.reload();
          },
        });
      }
    };

    onMounted(() => {
      itemStore.initLikedData([props.itemId]);
    });

    return {
      itemStore,
      isLiked,
      onClick,
    };
  },
});
