























import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { setToStorage } from '@/js/lib/helper/expirable-local-storage';

const KEY = 'close-modal-member-registration-coupon';
const TTL = 3 * 24 * 86400;

const storageReset = () => {
  setToStorage({ key: KEY, value: true, expirationSeconds: TTL });
};

export default defineComponent({
  setup() {
    const isSigninPage = window.location.pathname === '/signin';
    const isSignupPage = window.location.pathname === '/signup';
    const isShow = ref(false);
    const modalClose = () => {
      isShow.value = false;
      storageReset();
    };
    onMounted(() => {
      isShow.value = !isSigninPage && !isSignupPage;
    });

    return {
      isShow,
      storageReset,
      modalClose,
    };
  },
});
