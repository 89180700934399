// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IMenu__underline--ElmXt{border-bottom:1px dashed #121212}", "",{"version":3,"sources":["webpack://./assets/src/js/components/molecules/IMenu.vue"],"names":[],"mappings":"AA6CA,yBACE,gCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"underline": "IMenu__underline--ElmXt"
};
module.exports = ___CSS_LOADER_EXPORT___;
