
































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { useCommonStore } from '@/js/lib/store/common';
import { useUserStore } from '@/js/lib/store/user';
import { signin, signup } from '@/js/lib/api/user';
import { checkEmailExistence } from '@/js/lib/api/validate';
import { scrollToError } from '@/js/lib/helper/validate';
import { info, loading } from '@/js/lib/ui';
import ITextField from '@/js/components/molecules/ITextField.vue';
import IButton from '@/js/components/molecules/IButton.vue';

export default defineComponent({
  components: {
    ITextField,
    IButton,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    initialEmail: {
      type: String,
      required: false,
      default: '',
    },
    initialMemberName: {
      type: String,
      required: false,
      default: '',
    },
    initialTransactionToken: {
      type: String,
      required: false,
      default: '',
    },
    initialOauthProvider: {
      type: String,
      required: false,
      default: undefined,
    },
    isModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    from: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const socialProviders = [
      {
        id: 'facebook',
        label: 'Facebook',
        svg: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48"><path d="M48 24C48 10.7438 37.2562 0 24 0C10.7438 0 0 10.7438 0 24C0 35.9813 8.775 45.9094 20.25 47.7094V30.9375H14.1562V24H20.25V18.7125C20.25 12.6984 23.8313 9.375 29.3156 9.375C31.9406 9.375 34.6875 9.84375 34.6875 9.84375V15.75H31.6594C28.6781 15.75 27.75 17.6016 27.75 19.5V24H34.4062L33.3422 30.9375H27.75V47.7094C39.225 45.9094 48 35.9813 48 24Z" fill="#1877F2"/><path d="M33.3422 30.9375L34.4062 24H27.75V19.5C27.75 17.6016 28.6781 15.75 31.6594 15.75H34.6875V9.84375C34.6875 9.84375 31.9406 9.375 29.3156 9.375C23.8313 9.375 20.25 12.6984 20.25 18.7125V24H14.1562V30.9375H20.25V47.7094C21.4734 47.9016 22.725 48 24 48C25.275 48 26.5266 47.9016 27.75 47.7094V30.9375H33.3422Z" fill="white"/></svg>',
      },
      {
        id: 'twitter',
        label: 'Twitter',
        svg: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" fill="#1da1f2"/></svg>',
      },
    ];

    const { isWebView } = useCommonStore();
    const userStore = useUserStore();
    const observer = ref();
    const emailRef = ref();
    const email = ref(props.initialEmail);
    const password = ref('');
    const remember = ref(true);
    const memberName = ref(props.initialMemberName);
    const mailmagazine = ref(true);
    const transactionToken = ref(props.initialTransactionToken);
    const oauthProvider = ref(props.initialOauthProvider);

    const isSignin = computed(() => props.value === 'signin');
    const pageTitle = computed(() => (isSignin.value ? 'ログイン' : '会員登録'));
    const connectArr = computed(() =>
      socialProviders.map((item) => {
        const from = props.from ? `?from=${props.from}` : '';
        const url = `/${props.value}/with/${item.id}${from}`;
        return {
          id: item.id,
          label: item.label,
          svg: item.svg,
          url,
        };
      }),
    );
    const helpLink = computed(() =>
      isSignin.value
        ? 'https://help.iichi.com/hc/ja/articles/360000013721-%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E3%81%A7%E3%81%8D%E3%81%AA%E3%81%84%E3%81%AE%E3%81%AF%E3%81%AA%E3%81%9C-'
        : 'https://help.iichi.com/hc/ja/articles/200196409-%E4%BC%9A%E5%93%A1%E7%99%BB%E9%8C%B2%E3%81%AE%E6%89%8B%E9%A0%86%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84',
    );
    const socialProvider = computed(() => (provider) => socialProviders.find((p) => p.id === provider));
    const onSubmit = async () => {
      const isValid = await observer.value.validate();
      if (!isValid) {
        scrollToError(observer.value);
        return;
      }
      const emailExistence = await checkEmailExistence({ email: email.value });
      if (!isSignin.value && emailExistence !== true) {
        emailRef.value.$refs.provider.applyResult({
          errors: [emailExistence],
        });
        scrollToError(observer.value);
        return;
      }
      loading({
        asyncFunc: async () => {
          if (isSignin.value) {
            const res = await signin({
              email: email.value,
              password: password.value,
              remember: remember.value,
            });
            if (res.error === false) {
              userStore.setSignined();
              if (props.isModal) {
                emit('submit', props.value);
              } else {
                window.location.href = props.from || '/';
              }
            } else {
              info('メールアドレス、もしくはパスワードが違います。', { fadeOut: true });
            }
          } else {
            await signup({
              email: email.value,
              password: password.value,
              memberName: memberName.value,
              subscribeNewsletter: mailmagazine.value,
              transactionToken: transactionToken.value,
            });
            userStore.setSignined();
            if (props.isModal) {
              emit('submit', props.value);
            } else {
              window.location.href = props.from || '/';
            }
          }
        },
      });
    };
    const onClick = (name: string, $event) => {
      if (props.isModal) {
        emit('input', name);
      } else {
        window.location.href = $event.target.href;
      }
    };
    const close = () => {
      emit('close');
    };

    return {
      observer,
      emailRef,
      email,
      password,
      remember,
      memberName,
      mailmagazine,
      oauthProvider,
      isSignin,
      isWebView,
      pageTitle,
      connectArr,
      helpLink,
      socialProvider,
      onSubmit,
      onClick,
      close,
    };
  },
});
