import { readonly, ref, computed } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { credentials } from '@/js/lib/api/user';
import { useUserCredentialStore } from './user-credential';
import { useUserPermissionStore } from './user-permission';
import { getFromStorage, setToStorage } from '@/js/lib/helper/expirable-local-storage';

interface MemberData {
  member: {
    a: boolean;
    alias: string;
    b: boolean;
    member_id: number;
    member_name: string;
    email: string;
    picture_path: string;
    slug: string;
  };
}

const setSignined = () => {
  setToStorage({ key: 'signined', value: true, expirationSeconds: 86400 * 30 });
};

export const useUserStore = defineStore('user', () => {
  const { hasCredential, updateCredential, resetCredential } = useUserCredentialStore();
  const { hasPermission, updatePermission } = useUserPermissionStore();
  const memberId = ref<number>();
  const aliasOrSlug = ref('');
  const memberName = ref('');
  const email = ref('');
  const picturePath = ref('');
  const isAgentLogin = ref(false);
  const isAuthenticated = ref(false);
  const isBlackListed = ref(false);
  const isEmailConfirmed = computed(() => hasCredential(credentials.emailConfirmed));
  const hasSignined = computed(() => Boolean(getFromStorage({ key: 'signined' })));
  function assignMember({ member }: MemberData) {
    memberId.value = member.member_id;
    aliasOrSlug.value = member.alias || member.slug;
    memberName.value = member.member_name;
    email.value = member.email;
    picturePath.value = member.picture_path;
    isAgentLogin.value = member.a;
    isBlackListed.value = member.b;
    isAuthenticated.value = Boolean(member.member_id);
  }

  return {
    memberId: readonly(memberId),
    aliasOrSlug: readonly(aliasOrSlug),
    memberName: readonly(memberName),
    email: readonly(email),
    picturePath: readonly(picturePath),
    isAgentLogin: readonly(isAgentLogin),
    isBlackListed: readonly(isBlackListed),
    isAuthenticated: readonly(isAuthenticated),
    hasSignined,
    isEmailConfirmed,
    hasCredential,
    hasPermission,
    assignMember,
    setSignined,
    updateCredential,
    resetCredential,
    updatePermission,
  };
});
