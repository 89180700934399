












import { defineComponent, ref } from '@vue/composition-api';
import { Button as ElButton } from 'element-ui';
import { sleep } from '@/js/lib/helper/sleep';

export default defineComponent({
  components: {
    ElButton,
  },
  props: {
    type: {
      type: String,
      default: 'secondary',
    },
    size: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    flexible: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isShowRipple = ref(false);
    const showRipple = async () => {
      isShowRipple.value = true;
      await sleep(100);
      isShowRipple.value = false;
    };

    return {
      isShowRipple,
      showRipple,
    };
  },
});
