import env, { type User, type Member, type Topic } from './_env';
import { pinia } from './store';
import { useUserStore } from './store/user';

export { IichiEnv } from './_env';
export { CdnDomain } from './_config';

interface Options {
  iichiEnv: string;
  User?: User;
  Member?: Member;
  Topics?: Topic[];
}

export class Common {
  public env: typeof env;

  public userStore: ReturnType<typeof useUserStore>;

  constructor() {
    this.env = env;
    this.userStore = useUserStore(pinia);
  }

  initialize(options: Options) {
    this.userStore.updateCredential({ credentials: options.User.credentials });
    if (options.Member) {
      this.userStore.assignMember({ member: options.Member });
      this.userStore.updatePermission({ permissions: options.Member.permissions });
    }
  }
}

// 同一インスタンスを使用するためwindow下に保持
const common = window.common === undefined ? new Common() : window.common;
window.common = common;

export default common;
