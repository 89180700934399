import { readonly, ref, computed } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { checkNewItem, checkNewEvent } from '@/js/lib/api/user';

export const useTimelineStore = defineStore('timeline', () => {
  const itemUpdated = ref<boolean>(false);
  const eventUpdated = ref<boolean>(false);
  const isUpdated = computed(() => itemUpdated.value || eventUpdated.value);
  async function checkItemUpdated() {
    try {
      await checkNewItem();
      itemUpdated.value = true;
    } catch {
      itemUpdated.value = false;
    }
  }
  async function checkEventUpdated() {
    try {
      await checkNewEvent();
      eventUpdated.value = true;
    } catch {
      eventUpdated.value = false;
    }
  }

  return { isItemUpdated: readonly(itemUpdated), isEventUpdated: readonly(eventUpdated), isUpdated, checkItemUpdated, checkEventUpdated };
});
