import { ref, computed } from '@vue/composition-api';
import { defineStore } from 'pinia';

export const useUserPermissionStore = defineStore('userPermission', () => {
  const permissionsArr = ref<number[]>([]);
  const hasPermission = computed(() => (permission: number) => permissionsArr.value.includes(permission));

  function updatePermission({ permissions }: { permissions: number[] }) {
    permissions.forEach((permission: number) => {
      if (!permissionsArr.value.includes(permission)) {
        permissionsArr.value.push(permission);
      }
    });
  }

  return { hasPermission, updatePermission };
});
