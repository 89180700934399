import { readonly, ref, computed } from '@vue/composition-api';
import { defineStore } from 'pinia';
import * as model from '@/js/lib/api/notification';
import { type NotificationItem } from '@/js/lib/api/notification';

export const useNotificationStore = defineStore('notification', () => {
  const isLoaded = ref(false);
  const notifications = ref<NotificationItem[]>([]);
  const totalCount = ref(0);
  const updatedCount = ref(0);

  async function loadNotifications({ limit = 10 } = {}) {
    // 末尾のid
    const sinceId = notifications.value.length > 0 ? notifications.value.at(-1).id : undefined;

    try {
      const res = await model.getNotifications({ sinceId, limit });
      notifications.value = res.notifications;
      totalCount.value = res.totalCount;
      isLoaded.value = true;
    } catch {
      notifications.value = [];
      totalCount.value = 0;
      isLoaded.value = true;
    }
  }
  async function markAsChecked() {
    if (notifications.value.length > 0) {
      const notificationId = notifications.value[0].id;

      await model.markAsConfirmed({ notificationId });
      updatedCount.value = 0;
    }
  }
  async function checkUpdated() {
    try {
      const res = await model.checkUpdated();
      updatedCount.value = res.updatedCount;
    } catch {
      updatedCount.value = 0;
    }
  }

  return {
    isLoaded: readonly(isLoaded),
    notifications: readonly(notifications),
    totalCount: computed(() => Number(totalCount.value)),
    updatedCount: computed(() => Number(updatedCount.value)),
    loadNotifications,
    markAsChecked,
    checkUpdated,
  };
});
