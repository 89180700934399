









import { defineComponent, onMounted, ref } from '@vue/composition-api';
import IMenu from '@/js/components/molecules/IMenu.vue';
import { getFromStorage, setToStorage } from '@/js/lib/helper/expirable-local-storage';
import { useMemberStore } from '@/js/lib/store/member';

export default defineComponent({
  components: {
    IMenu,
  },
  props: {
    memberId: {
      type: Number,
      required: true,
    },
    bump: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const KEY = 'close-follow-balloon';
    const TTL = 24 * 86400;
    const isShow = ref(false);
    const memberStore = useMemberStore();
    const onMenuHide = () => {
      setToStorage({ key: KEY, value: true, expirationSeconds: TTL });
    };

    onMounted(() => {
      isShow.value = !memberStore.followedMemberIds.includes(props.memberId) && !getFromStorage({ key: KEY });
    });

    return {
      isShow,
      onMenuHide,
    };
  },
});
