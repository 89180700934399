import axios from '@/js/lib/axios';
import { updateToken } from './update_token';

export const messageStart = async ({ itemId, conversationType, shopId, pictures, subjectStr, messageStr }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('conversation[conversation_type]', conversationType);
  if (itemId) {
    params.append('conversation[link_id]', itemId);
  }
  params.append('conversation[to_member_id]', shopId);
  params.append('conversation[picture][filename]', pictures[0] ? pictures[0].filename || '' : '');
  params.append('conversation[subject]', subjectStr);
  params.append('conversation[message]', messageStr);
  params.append('conversation[token]', token);
  const res = await axios.post('/api/message/start', params);
  return res.data;
};

export const checkUnreadCount = async (): Promise<{ unreadCount: number }> => {
  const res = await axios.get('/api/message/unread-count');
  return {
    unreadCount: Number(res.headers['x-unread-count']),
  };
};
