







import { defineComponent, computed } from '@vue/composition-api';
import { Popover as ElPopover } from 'element-ui';

export default defineComponent({
  components: {
    ElPopover,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasUnderline: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isShow = computed({
      get() {
        return props.value;
      },
      set(newVal) {
        emit('input', newVal);
      },
    });

    return {
      isShow,
    };
  },
});
