






























import { defineComponent, ref, computed, onMounted, onUnmounted, watchEffect, type PropType } from '@vue/composition-api';
import { dynamicImageUrl } from '@/js/lib/helper/url';
import { linkify } from '@/js/lib/helper/linkify';
import type Swiper from 'swiper';

export default defineComponent({
  props: {
    list: {
      type: Array as PropType<
        {
          path: string;
          width: number;
          height: number;
          comment: string;
        }[]
      >,
      required: false,
      default: () => [],
    },
    currentIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    isShowModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    injectStyles: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    const wrapperRef = ref();
    const isMounted = ref(false);
    const newList = computed(() => props.list);
    const isShow = computed(() => props.isShowModal);
    const swiper = ref<Swiper>();
    const current = computed(() => (swiper.value?.realIndex as number) || 0);
    const comment = computed(() => newList.value[current.value].comment);

    onMounted(() => {
      isMounted.value = true;
    });

    onUnmounted(() => {
      swiper.value?.destroy();
    });

    watchEffect(() => {
      if (!swiper.value && isMounted.value && isShow.value && newList.value.length > 1) {
        Object.assign(wrapperRef.value, {
          navigation: true,
          zoom: true,
          initialSlide: props.currentIndex,
          injectStyles: props.injectStyles,
        });

        wrapperRef.value.addEventListener('swiperprogress', (e) => {
          [swiper.value] = e.detail;
        });
        wrapperRef.value.initialize();
      }
    });

    return {
      wrapperRef,
      newList,
      isShow,
      current,
      comment,
      linkify,
      dynamicImageUrl,
    };
  },
});
