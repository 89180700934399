











import { defineComponent, ref } from '@vue/composition-api';
import IModal from '@/js/components/molecules/IModal.vue';

export default defineComponent({
  components: {
    IModal,
  },
  props: {
    hasUnderline: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isShow = ref(false);

    return {
      isShow,
    };
  },
});
